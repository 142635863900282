import React from 'react';
import {makeStyles} from '@mui/styles';
import {TextInput, useCreate, useNotify, useRedirect} from 'react-admin';
import {Controller, FormProvider, useForm} from 'react-hook-form';
import {Button, Card, CardActions} from '@mui/material';

const useStyles = makeStyles((theme) => ({
    main: {
        paddingTop: '12em',
        backgroundColor: '#0c2461',
        backgroundImage: 'url(login-bg.svg)',
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        alignItems: 'center',
        justifyContent: 'flex-start',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
    },
    wrapper: {
        padding: '1em',
        minWidth: 300,
    },
    actions: {
        padding: 0,
        marginTop: '1em'
    },

}))

export const ForgotPassword = () => {

    const classes = useStyles();

    const form = useForm();
    const notify = useNotify();
    const redirect = useRedirect();
    const [create] = useCreate();

    const onSubmit = (formData) => {
        create(
            'reset-password',
            {
                data: formData,
                meta: {
                    resourceSuffix: false,
                    isPublicRequest: true
                }
            },
            {
                onSuccess: (data) => {
                    redirect('/login')
                    notify('Password sent to email')
                },
                onError: () => {
                    notify('Something went wrong')
                }
            })
    }

    return (
        <FormProvider {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)}>
                <div className={classes.main}>
                    <Card classes={classes.card}>
                        <div className={classes.wrapper}>
                            <div>
                                Enter your email:
                            </div>
                            <Controller name="email"
                                        control={form.control}
                                        rules={{required: 'Email is required'}}
                                        render={({field}) => (
                                            <TextInput {...field}
                                                       ref={null}
                                                       autoFocus
                                                       fullWidth
                                                       label="Email"
                                                       helperText={false}
                                                       rules={{
                                                           required: 'Email is required',
                                                           pattern: {
                                                               value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                               message: 'Invalid email address'
                                                           }
                                                       }}
                                                       autoComplete="off"/>
                                        )}/>
                            <CardActions className={classes.actions}>
                                <Button variant="contained"
                                        type="submit"
                                        className={classes.button}
                                        color="primary"
                                        fullWidth>
                                    Reset password
                                </Button>
                            </CardActions>
                        </div>
                    </Card>
                </div>
            </form>
        </FormProvider>
    );
}
