import {
	Button,
	Datagrid,
	FunctionField,
	ReferenceManyField,
	SelectField,
	TextField,
	useNotify,
	useRecordContext,
	useRefresh,
	useUpdate
} from "react-admin";
import PaginationMod from "../../Component/Redesign/PaginationMod";
import {
	SOCIAL_CHANNEL_LABELS,
	SOCIAL_CHANNEL_STATUSES,
	SOCIAL_CHANNEL_STATUSES_LIST,
	SOCIAL_CHANNEL_SUB_STATUSES_LIST,
	SOCIAL_CHANNEL_TYPES
} from "../../Utils/constant/Const";
import {getFinancialNumberWithCurrency, reformatNumber} from "../../Utils/helpers/reformat-number";
import DateFieldMod from "../../Component/Redesign/DateFieldMod";
import React, {useState} from "react";
import {useGet} from "../../Hooks/useGet";
import {Block, Check, Edit} from "@mui/icons-material";
import UrlField from "../../Component/Redesign/UrlField";
import {Typography} from "@mui/material";
import {UpdateOffsetModal} from "./UpdateOffsetModal";
import EditLinkModal from "./EditChannelLink";

const ChangeLink = () => {

	const [showEditModal, setShowEditModal] = useState(false)

	return <>

		<Button children={<Edit/>} label="Edit" sx={{marginRight: '16px'}} onClick={() => setShowEditModal(true)}/>
		{
			showEditModal &&
			<EditLinkModal closeModal={() => setShowEditModal(false)}/>
		}
	</>
}

const VerifySocialChannel = () => {
	const record = useRecordContext()
	const notify = useNotify()
	const refresh = useRefresh()
	const [updateChannelStatus, {isLoading}] = useUpdate()

	const oUpdateChannelStatus = (verify) => {
		if (isLoading) {
			return
		}
		updateChannelStatus(
			'social-networks/link',
			{
				id: record.channel.id,
				data: {status: verify ? 1 : 2},
				meta: {resourceSuffix: ''},
			},
			{
				onSuccess: () => {
					refresh()
					notify(verify ? 'Channel verified' : 'Channel rejected')
				},
				onError: (error) => {
					refresh()
					notify(error.message, {type: 'error'})
				}
			}).then()

	}

	return <div style={{display: 'flex'}}>
		<Button children={<Check/>} label="Verify" sx={{marginRight: '16px'}}
						onClick={() => oUpdateChannelStatus(true)}/>
		<Button children={<Block color="warning"/>} color="warning" label="Reject"
						onClick={() => oUpdateChannelStatus(false)}/>

	</div>
}

const ChannelUserOffset = () => {
	const record = useRecordContext()
	const [openModal, setOpenModal] = useState(false)

	const offset = record.channel.offset ?? 0


	return <Typography sx={{display: "flex"}}>
		<p style={{whiteSpace: 'nowrap'}}>{getFinancialNumberWithCurrency(offset)}</p>
		<Button children={<Edit/>} sx={{marginLeft: '8px'}} onClick={() => setOpenModal(true)}/>
		{
			openModal &&
			<UpdateOffsetModal offset={offset} channelId={record.channel.id} onClose={() => setOpenModal(false)}/>
		}
	</Typography>
}

const SocialChannelsByUser = () => {
	const record = useRecordContext()
	const userId = record.id;

	const {data} = useGet('social-networks/total-market-value', {meta: {query: {'filter[userId]': userId}}})

	return <>
		{
			!!data?.totalMarketValue &&
			<div>
				<h3>Total market value: {getFinancialNumberWithCurrency(data.totalMarketValue)}</h3>
			</div>
		}
		<ReferenceManyField source="id"
												reference={`social-networks`}
												target="userId"
												label={false}
												emptyText="No results found"
												pagination={<PaginationMod/>}>
			<Datagrid bulkActionButtons={false}>
				<TextField label="Id" source="id"/>
				<TextField label="Channel name" source="channel.name" sortBy="name"/>
				<FunctionField label="Platform"
											 render={record => record?.channel?.type ? SOCIAL_CHANNEL_LABELS[record.channel.type] : ''}
											 sortable={false}/>
				<FunctionField render={record => getFinancialNumberWithCurrency(record?.channel?.marketValue)}
											 label="Market value" sortable={false}/>
				<FunctionField
					render={record => reformatNumber(record.channel?.followers)}
					label="Followers" sortable={false}/>
				<SelectField sortable={false} label={'Status'} source="channel.status" choices={SOCIAL_CHANNEL_STATUSES_LIST}/>
				<FunctionField label={'Fail reason'} sx={{whiteSpace: 'nowrap'}} render={record => {
					const subStatus = record?.channel?.subStatus
					return subStatus ?
						<SelectField sortable={false} label={'Failed reason'} source="channel.subStatus"
												 choices={SOCIAL_CHANNEL_SUB_STATUSES_LIST}/> :
						''
				}}/>
				<FunctionField label="Adjustment" render={() => <ChannelUserOffset/>}/>
				<DateFieldMod showTime label="Date created" source="channel.createdAt" sortBy="createdAt"/>
				<UrlField
					label={false}
					source="channel.socialChannelLink"
					sortable={false}
					target='_blank'
					rel="noopener noreferrer"
				/>
				<FunctionField render={record =>
					record?.channel?.type === SOCIAL_CHANNEL_TYPES.youtubeMusic || record?.channel?.type === SOCIAL_CHANNEL_TYPES.spotify ?
						<ChangeLink/> : null
				}/>
				<FunctionField render={record =>
					record?.channel?.status === SOCIAL_CHANNEL_STATUSES.unverified ?
						<VerifySocialChannel/> : null
				}/>
			</Datagrid>
		</ReferenceManyField>
	</>
}

export default SocialChannelsByUser;
