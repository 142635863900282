import { stringify } from 'qs';
//import decodeJwt from 'jwt-decode';
import inMemoryJWT from './inMemoryJwt';
import { ENV } from '../Const/ProjectEnv';

const apiUrl = ENV.HOST_BACKEND + '/admin';

// eslint-disable-next-line
export default {
    // called when the user attempts to log in
    login: ({ username, password }) => {
        const request = new Request(apiUrl + `/login`, {
            method: 'POST',
            body: stringify({
                username: username,
                password: password
            }),
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded'
            })
        });
        return fetch(request)
            .then((response) => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }
                return response.json();
            })
            .then(data => {
                if (!data.status && data.errors) {
                    throw new Error(data.message);
                }

                if (data.status) {

                    return data;
                }
            })
            .then(({ data }) => {
                inMemoryJWT.setToken(data.token, data.refreshToken || data.refresh_token)
            });
    },
    // called when the user clicks on the logout button
    logout: () => {
        inMemoryJWT.ereaseToken();
        return Promise.resolve();
    },
    // called when the API returns an error
    checkError: (err) => {
        if (!inMemoryJWT.getToken()) {
            return Promise.reject({ redirectTo: '/login' })
        }
        if (err?.status === 401) {
            inMemoryJWT.ereaseToken();
            return Promise.reject();
        }
        return Promise.resolve();
    },
    // called when the user navigates to a new location, to check for authentication
    checkAuth: async () => {
        return inMemoryJWT.waitForTokenRefresh().then(() => {
            return inMemoryJWT.getToken() ? Promise.resolve() : Promise.reject({ redirectTo: '/login' });
        });
    },
    // called when the user navigates to a new location, to check for permissions / roles
    getPermissions: () => {
        return inMemoryJWT.waitForTokenRefresh().then(() => {
            return inMemoryJWT.getToken() || window?.location?.hash?.includes('forgot-password')
                ? Promise.resolve(inMemoryJWT.getRoles())
                : Promise.reject({ redirectTo: '/login' });
        });
    },
};
