import {useState} from 'react';
import {Controller, FormProvider, useForm} from 'react-hook-form';
import {Avatar, Button, Card, CardActions, CircularProgress,} from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';
import {Notification, PasswordInput, TextInput, useLogin, useNotify, useTranslate} from 'react-admin';
import {makeStyles} from '@mui/styles';
import {Link} from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
	main: {
		backgroundColor: '#0c2461',
		backgroundImage: 'url(login-bg.svg)',
		display: 'flex',
		flexDirection: 'column',
		minHeight: '100vh',
		alignItems: 'center',
		justifyContent: 'flex-start',
		backgroundRepeat: 'no-repeat',
		backgroundSize: 'cover',
	},
	card: {
		minWidth: 300,
		marginTop: '12em',
	},
	avatar: {
		margin: '1em',
		display: 'flex',
		justifyContent: 'center',
	},
	hint: {
		marginTop: '1em',
		display: 'flex',
		justifyContent: 'center',
		color: 'black',
	},
	form: {
		padding: '0 1em 1em 1em',
	},
	input: {
		marginTop: '1em',
	},
	actions: {
		padding: '0 1em 1em 1em',
	},
	forgotPassword: {
		display: 'flex',
		justifyContent: 'flex-end',
		padding: '0 1em 1em 1em',
	},
	forgotPasswordLink: {
		textDecoration: 'none',
		color: '#2196f3',
		'&:hover': {
			color: '#0069c0'
		}
	}

}))

const LoginForm = (props) => {

	const classes = useStyles(props);

	const login = useLogin();
	const notify = useNotify();
	const translate = useTranslate();

	const form = useForm({
		defaultValues: {
			username: '',
			password: ''
		}
	});

	const [loading, setLoading] = useState(false);

	const submit = values => {
		setLoading(true);
		login(values, '/overview/banking-stats')
			.then(() => {
				setLoading(false);
			})
			.catch(error => {
				setLoading(false);
				notify(
					typeof error === 'string'
						? error
						: typeof error === 'undefined' || !error.message
							? 'ra.auth.sign_in_error'
							: error.message,
					'warning'
				);
			});
	};

	return (
		<FormProvider {...form}>
			<form onSubmit={form.handleSubmit(submit)}>
				<div className={classes.main}>
					<Card className={classes.card}>
						<div className={classes.avatar}>
							<Avatar className={classes.icon}>
								<LockIcon/>
							</Avatar>
						</div>
						<div className={classes.form}>
							<div className={classes.input}>
								<Controller name="username"
														control={form.control}
														rules={{required: 'Email is required'}}
														render={({field}) => (
															<TextInput {...field}
																				 ref={null}
																				 fullWidth
																				 label="Email"
																				 autoFocus
																				 autoComplete="off"
																				 disabled={loading}/>
														)}/>
							</div>
							<div className={classes.input}>
								<Controller name="password"
														control={form.control}
														rules={{required: 'Password is required'}}
														render={({field}) => (
															<PasswordInput {...field}
																						 ref={null}
																						 label="Password"
																						 disabled={loading}
																						 autoComplete="new-password"
																						 fullWidth/>
														)}/>
							</div>
						</div>
						<CardActions className={classes.actions}>
							<Button variant="contained"
											type="submit"
											color="primary"
											disabled={loading}
											fullWidth>
								{loading && <CircularProgress size={25} thickness={2}/>}
								{translate('ra.auth.sign_in')}
							</Button>
						</CardActions>
						<div className={classes.forgotPassword}>
							<Link className={classes.forgotPasswordLink}
										to="/forgot-password">
								Forgot password ?
							</Link>
						</div>
					</Card>
					<Notification/>
				</div>
			</form>
		</FormProvider>
	);
};

export default LoginForm;
